function NotFound() {
  return (
    <>
      <h2>Oops!</h2>
      <p>Nothing here..</p>
    </>
  );
}

export default NotFound;
